var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { deleteCode, getAllCode, getOneCode, reLoadCode, updateCode } from './actions';
var initialState = {
    codes: { docs: [], meta: {} },
    code: {},
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'codes',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(getAllCode.pending, function (state) {
            state.status = IStatus.LOADING;
        });
        builder.addCase(getAllCode.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.codes = payload;
        });
        builder.addCase(getAllCode.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        builder.addCase(reLoadCode.pending, function (state) {
            state.status = IStatus.LOADING;
        });
        builder.addCase(reLoadCode.fulfilled, function (state) {
            state.status = IStatus.SUCCEEDED;
        });
        builder.addCase(reLoadCode.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        builder.addCase(getOneCode.pending, function (state) {
            state.status = IStatus.LOADING;
        });
        builder.addCase(getOneCode.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.code = __assign({}, payload.data);
        });
        builder.addCase(getOneCode.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        builder.addCase(updateCode.pending, function (state) {
            state.status = IStatus.LOADING;
        });
        builder.addCase(updateCode.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.code = __assign({}, payload.data);
        });
        builder.addCase(updateCode.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        builder.addCase(deleteCode.pending, function (state) {
            state.status = IStatus.LOADING;
        });
        builder.addCase(deleteCode.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            var codeId = payload.codeId;
            state.codes.docs = state.codes.docs.filter(function (elt) { return elt._id !== codeId; });
        });
        builder.addCase(deleteCode.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export var actions = slice.actions;
export default slice.reducer;
